import React, { useState } from "react";
/* eslint-disable object-curly-newline */
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const NewMamModal = ({ toggleModal, handleModal }) => {
  const { t } = useTranslation();
  const [allocationType, setAllocationType] = useState();
  const [deductionTimeScheduleType, setDeductionTimeScheduleType] = useState();
  const [performanceFee, setPerformanceFee] = useState(0);
  const [managementFee, setManagementFee] = useState(0);
  const [periodicManagement, setPeriodicManagement] = useState(0);
  const [perLotFee, setPerLotFee] = useState(0);

  return (
    <Modal size="lg" isOpen={handleModal} toggleModal={toggleModal} centered>
      <ModalHeader toggleModal={toggleModal}>
        {t("Create New Profile")}
      </ModalHeader>
      <ModalBody>
        <div className="d-flex justify-content-between align-items-center">
          <label>{t("Allocation Type")}</label>
          <div className="w-50 p-2">
            <Select
              name="profile"
              onChange={(e) => {
                setAllocationType(e.value);
              }}
              value={allocationType === null ? "" : undefined}
              options={[
                // {
                //   label: "Equity Lot Share - MAM",
                //   value: "Equity Lot Share - MAM",
                // },
                {
                  label: "Equity Cash Share - PAM",
                  value: "Equity Cash Share - PAM",
                },
              ]}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <label>{t("Deduction Time Schedule")}</label>
          <div className="w-50 p-2">
            <Select
              name="profile"
              onChange={(e) => {
                setDeductionTimeScheduleType(e.value);
              }}
              value={deductionTimeScheduleType === null ? "" : undefined}
              options={[
                {
                  label: "Select...",
                  value: "null",
                },
                {
                  label: "Daily",
                  value: "Daily",
                },
              ]}
            />
          </div>
        </div>
        <hr className="m-3" />
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <label>{t("Performance Fee - (%)")}</label>
            <div className="p-2 w-50">
              <input
                className="p-1"
                onChange={(e) =>
                  e.target.value < 0
                    ? setPerformanceFee(0)
                    : setPerformanceFee(e.target.value)
                }
                value={performanceFee}
                type="number"
              />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <label>{t("Management Fee - ($)")}</label>
            <div className="p-2 w-50">
              <input
                className="p-1"
                onChange={(e) =>
                  e.target.value < 0
                    ? setManagementFee(0)
                    : setManagementFee(e.target.value)
                }
                value={managementFee}
                type="number"
              />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <label>{t("Periodic Management Fee - ($)")}</label>
            <div className="p-2 w-50">
              <input
                className="p-1"
                onChange={(e) =>
                  e.target.value < 0
                    ? setPeriodicManagement(0)
                    : setPeriodicManagement(e.target.value)
                }
                value={periodicManagement}
                type="number"
              />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <label>{t("Per Lot Fee - ($)")}</label>
            <div className="p-2 w-50">
              <input
                className="p-1"
                onChange={(e) =>
                  e.target.value < 0
                    ? setPerLotFee(0)
                    : setPerLotFee(e.target.value)
                }
                value={perLotFee}
                type="number"
              />
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="white" onClick={toggleModal}>
          {t("Cancel")}
        </Button>
        <Button color="primary" onClick={toggleModal}>
          {t("Submit")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default NewMamModal;
