import * as axiosHelper from "./api_helper";
import qs from "qs";

export const getDeposits = async ({ payload })=>{
  const deposits = await axiosHelper.get(`/transactions/deposit?${qs.stringify(payload)}`);
  return deposits;
};
export const makeDeposit = async (values)=>{
  const result = await axiosHelper.post("/transactions/deposit", values);
  if (result.code === 422){
    throw new Error("Deposit has failed");
  }
  return result ;
};
export const makeFormDataDeposit = async (values)=>{
  const result = await axiosHelper.postFormData("/transactions/deposit", values);
  if (result.code === 422){
    throw new Error("Deposit has failed");
  }
  return result ;
};
export const aprroveDeposit = async (values)=>{
  const result = await axiosHelper.patch(`/transactions/deposit/${values.id}/approve`, { ...values });
  if (result.isError){
    throw new Error(result.message);
  }
  return result;
};

export const approveFxDepositAPI = async (values)=>{
  const result = await axiosHelper.patch(`/fxtransactions/deposit/${values.id}/approve`, { ...values });
  if (result.isError){
    throw new Error(result.message);
  }

  return result;
};
export const rejectDeposit = async (values)=>{
  const result = await axiosHelper.patch(`/transactions/deposit/${values.id}/reject`, { ...values });
  if (result.isError){
    throw new Error(result.message);
  }
  
  return result;
};

export const getClientDeposits = async ({ payload }) => {
  const { clientId, type, page, limit } = payload;
  const deposits = await axiosHelper.get(`/transactions/deposit?customerId=${clientId}&type=${type}&page=${page}&limit=${limit}`);
  const data = { 
    deposits: deposits
  };
  if (deposits.isError){
    throw new Error(data.isError);
  }

  return data;
};
