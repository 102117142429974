import React, { useState, useEffect } from "react";
import {
  useDispatch, connect, useSelector
} from "react-redux";
import {
  Modal, ModalHeader,
  ModalBody,
  Row, Col, UncontrolledAlert, Button,
} from "reactstrap";
import { withTranslation } from "react-i18next";

function RegisterationTime(props) {
  const {show, onClose, client} = props;
  const [addModal, setAddTodoModal] = useState(false);
  
  const calculateTimeDifference = (createdAt, startedAt) => {
    const createdTime = new Date(createdAt);
    const startTime = new Date(startedAt);
  
    const differenceInMilliseconds = createdTime - startTime;
    const seconds = Math.floor(differenceInMilliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
  
    const timeDifference = {
      days: days % 365,
      hours: hours % 24,
      minutes: minutes % 60,
      seconds: seconds % 60,
    };
  
    return timeDifference;
  };

  const registerationTime = calculateTimeDifference(client?.createdAt, client?.startTime);
  const submitProfileTime = calculateTimeDifference(client?.submitProfileEndTime, client?.stages?.individual?.submitProfileStartTime);
  const openAccountTime = calculateTimeDifference(client?.openAccountEndTime, client?.submitProfileEndTime);
  const verifyDocsTime = calculateTimeDifference(client?.verifyDocumentsEndTime, client?.openAccountEndTime);
  const depositFundsTime = calculateTimeDifference(client?.depositFundsEndTime, client?.verifyDocumentsEndTime);
  const startTradingTime = calculateTimeDifference(client?.startTradingEndTime, client?.depositFundsEndTime);

  const toggleAddModal = () => {
    setAddTodoModal(!addModal);
    if (onClose) {
      onClose();
    }
  };


  return (
    <React.Fragment >
      <Modal isOpen={show} toggle={toggleAddModal} centered={true}>
        <ModalHeader toggle={toggleAddModal} tag="h4">
          Registeration Time
        </ModalHeader>
        <ModalBody >
          <Row>
            <p>
              {`Registeration Time: ${registerationTime.days} days, ${registerationTime.hours} hours, ${registerationTime.minutes} minutes, ${registerationTime.seconds} seconds`}
            </p>
          </Row>
          <Row>
            <p>
              {`Submit Profile Time: ${submitProfileTime.days} days, ${submitProfileTime.hours} hours, ${submitProfileTime.minutes} minutes, ${submitProfileTime.seconds} seconds`}
            </p>
          </Row>
          <Row>
            <p>
              {`Open Account Time: ${openAccountTime.days} days, ${openAccountTime.hours} hours, ${openAccountTime.minutes} minutes, ${openAccountTime.seconds} seconds`}
            </p>
          </Row>
          <Row>
            <p>
              {`Verify Documents Time: ${verifyDocsTime.days} days, ${verifyDocsTime.hours} hours, ${verifyDocsTime.minutes} minutes, ${verifyDocsTime.seconds} seconds`}
            </p>
          </Row>
          <Row>
            <p>
              {`Deposit Funds Time: ${depositFundsTime.days} days, ${depositFundsTime.hours} hours, ${depositFundsTime.minutes} minutes, ${depositFundsTime.seconds} seconds`}
            </p>
          </Row>
          <Row>
            <p>
              {`Start Trading Time: ${startTradingTime.days} days, ${startTradingTime.hours} hours, ${startTradingTime.minutes} minutes, ${startTradingTime.seconds} seconds`}
            </p>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}


const mapStateToProps = (state) => ({
  error: state.todosReducer.addError,
  adding: state.todosReducer.adding,
  loading: state.todosReducer.loading,
  todosPermissions: state.Profile.todosPermissions || {},
  clearingCounter: state.todosReducer.clearingCounter || 0,
});
export default connect(mapStateToProps, null)(withTranslation()(RegisterationTime));
