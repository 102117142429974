import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Row,
  Col
} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import { getStatementDeals } from "apis/client";
import { showErrorNotification } from "store/actions";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";
import moment from "moment";

export default function DownloadStatement(props) {
  const { t } = useTranslation();
  const [linkClientModal, setLinkClientModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const toggle = () => setLinkClientModal(!linkClientModal);
  const [dateFrom, setDateFrom] = useState(String(moment().subtract(30, "days").format("YYYY-MM-DD")));
  const [dateTo, setDateTo] = useState(String(moment().add(1, "day").format("YYYY-MM-DD")));

  const fetchStatement = (params) => {
    setLoading(true);
    getStatementDeals({
      payload: {
        ...params
      },
    }).then((res) => {
      if (res?.status) {
        const docs = res?.result?.docs || [];
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(docs?.filter(doc => doc?.clientDeal?.login === props.clientLogin).map(item => {
          return {
            "IB DEAL": item?.dealId,
            "IB LOGIN": item?.login,
            "IB DEAL DATE": moment(item?.time * 1000).format("DD/MM/YYYY HH:mm A ZZ"),
            "Client Deal Id": item?.clientDeal?.dealId,
            "Client Action": item?.clientDeal?.action === 0 ? "Buy" : "Sell",
            "Client Position Id": item?.clientDeal?.positionId,
            "Client Login": item?.clientDeal?.login,
            "Symbol":  (item?.clientDeal?.symbol || "-"),
            "Client Profit":  (item?.clientDeal?.profit || "-"),
            "Client Deal Time": (moment(item?.clientDeal?.time * 1000).format("YYYY-MM-DDTHH:mm") || "-"),
            "Client Volume": props?.entry === 0 ? parseFloat(item?.clientDeal?.volume / 10000)?.toFixed(2) : parseFloat(item?.clientDeal?.volumeClosed / 10000)?.toFixed(2),
            [props.entry === 0 ? "Commission" : "Rebate"]: parseFloat(item?.profit)?.toFixed(3),
            "Registered At": (moment(item?.createdAt).format("YYYY-MM-DDTHH:mm") || "-"),
          };
        }));
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, `${props.clientLogin}.xlsx`);
        toggle();
      } else {
        throw new Error(res?.message);
      }
    }).catch((err) => {
      dispatch(showErrorNotification(err?.message));
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleSubmit = (e, v) => {
    const { page, limit } = v;
    const {
      platform = "MT5",
      customerId,
      entry = 0,
      clientLogin,
    } = props;
    fetchStatement({
      page,
      limit,
      platform,
      customerId,
      entry,
      dateFrom,
      dateTo,
      clientLogin
    });
  };

  const handleDateFrom = (e) => setDateFrom(e.target.value);
  const handleDateTo = (e) => setDateTo(e.target.value);

  return (
    <React.Fragment >
      <button
        type="button"
        className="btn btn-primary waves-effect waves-light me-1"
        onClick={toggle}
      >
        {t("Download Statement")}
      </button>
      <Modal isOpen={linkClientModal} toggle={toggle} centered={true}>
        <ModalHeader toggle={toggle} tag="h4">
          {t("Download Statement")}
        </ModalHeader>
        <ModalBody >
          <AvForm
            className='p-4'
            onValidSubmit={handleSubmit}
          >
            <Row className="mb-3">
              <Col>
                <Label>{t("Login")}</Label>
                <AvField
                  name="login"
                  type="number"
                  className="form-control"
                  value={props.clientLogin}
                  disabled
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Label>{t("Page")}</Label>
                <AvField
                  name="page"
                  type="number"
                  className="form-control"
                  value={1}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Label>{t("Limit")}</Label>
                <AvField
                  name="limit"
                  type="number"
                  className="form-control"
                  value={2000}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12" md="12" lg="12">
                <AvField
                  className="mt-1 mb-2"
                  type="date"
                  name="dateFrom"
                  label={t("From Date")}
                  value={dateFrom}
                  validate={{ date: { format: "YYYY-MM-DD" } }}
                  onChange={handleDateFrom}
                />
              </Col>
              <Col xs="12" md="12" lg="12">
                <AvField
                  className="mt-1 mb-2"
                  type="date"
                  name="dateTo"
                  label={t("To Date")}
                  validate={{ date: { format: "YYYY-MM-DD" } }}
                  value={dateTo}
                  onChange={handleDateTo}
                />
              </Col>
            </Row>
            <div className='text-center pt-3 p-2'>
              <Button type="submit" color="primary" className=""
                disabled={loading}
              >
                {loading ? t("Loading...") : t("Download")}
              </Button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    </React.Fragment >
  );
}
