/* eslint-disable object-curly-newline */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { Container, Button } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table";
import { AvForm } from "availity-reactstrap-validation";

import { fetchReferrals } from "store/client/actions";
import { fetchAgreements, showSuccessNotification } from "store/actions";

import RecursiveTableRows from "./RecursiveTableRows";
import Select from "react-select";
import { fetchMamProfiles } from "store/mam-pam/actions";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { cpUrl } from "content";
import { profiles as allocationTypes } from "common/mam-pamm";
import CustomSelect from "components/Common/CustomSelect";

const Referral = ({ t }) => {
  const dispatch = useDispatch();

  const { _id, referrals, fx } = useSelector(
    (state) => state.clientReducer?.clientDetails
  );
  const { agreements } = useSelector((state) => state.ibAgreements);
  const { profiles, loading } = useSelector((state) => ({
    profiles: state.mamPamReducer.profile?.profiles,
    loading: state.mamPamReducer.profile?.loading,
  }));

  const [selectedAgreement, setSelectedAgreement] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [selectedReferral, setSelectedReferral] = useState(null);
  const allocationType = allocationTypes.find((item) => item.value === selectedProfile?.profileType)?.label ?? "-";

  useEffect(() => {
    if (_id) {
      _id &&
        dispatch(
          fetchReferrals({
            type: "live",
            clientId: _id,
          })
        );
      dispatch(fetchAgreements({ customerId: _id }));
      dispatch(fetchMamProfiles({
        customerId: _id,
        page: 1,
        limit: 100,
      }));
    }
  }, [_id]);

  const renderBody = (type) => {
    if (type === "IB") {
      return (
        <Table className="table table-hover text-center">
          <Thead>
            <Tr>
              <Th className="py-2 text-start">{t("Name")}</Th>
              <Th className="py-2 text-start">{t("Parent Name")}</Th>
              <Th className="py-2">{t("Client Type")}</Th>
              <Th className="py-2">{t("Accounts")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            <RecursiveTableRows
              data={referrals[0]}
              filter={selectedAgreement}
              level={0}
            />
          </Tbody>
        </Table>
      );
    } else if (type === "SP" && selectedProfile?.recordId) {
      return (
        <>
          <Table className="table table-hover text-center">
            <Thead>
              <Tr>
                <Th className="py-2 text-start">{t("Profile Type")}</Th>
                <Th className="py-2 text-start">{t("Master Login")}</Th>
                <Th className="py-2">{t("Fee Login")}</Th>
                <Th className="py-2">{t("Members")}</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Th className="py-2 text-start">{allocationType}</Th>
                <Th className="py-2 text-start">{selectedProfile?.masterTradingAccountId?.login}</Th>
                <Th className="py-2">{selectedProfile?.feeTradingAccountId?.login}</Th>
                <Th className="py-2">{selectedProfile?.slaves?.length}</Th>
              </Tr>
            </Tbody>
          </Table>
          <Card>
            <CardHeader className="py-3">
              <CardTitle className="mb-0">{t("Dedicated Link")}</CardTitle>
            </CardHeader>
            <CardBody>
              <Link to="#"
                className="mdi mdi-clipboard-check-multiple-outline font-size-20 me-2"
                onClick={() => {
                  navigator.clipboard.writeText(`${cpUrl}/register/client/investor?spRef=${selectedProfile?.recordId}`);
                  dispatch(showSuccessNotification("Link copied to clipboard"));
                }}
              ></Link>
              {`${cpUrl}/register/client/investor?spRef=${selectedProfile?.recordId}`}
            </CardBody>
          </Card>
        </>
      );
    }
  };

  return (
    <Container fluid className="gx-4" style={{
      minHeight: "60vh",
    }}>
      <AvForm className="d-flex">
        <div className="row m-2">
          <label>{t("Referrals For")}</label>
          <CustomSelect
            className="w-full"
            name="referral"
            onChange={(e) => {
              setSelectedReferral(e.value);
            }}
            value={selectedReferral === null ? "" : undefined}
            options={
              (()=>{
                const options = [];
                if (fx?.isIb) {
                  options.push({
                    label: "IB",
                    value: "IB",
                  });
                }
                if (fx?.isSp) {
                  options.push({
                    label: "Signal Provider",
                    value:"SP",
                  });
                }
                return options;
              })()
            }
          />
        </div>
        {
          selectedReferral === "IB" && <>
            <div className="row m-2">
              <label>{t("Select Agreement")}</label>
              <Select
                className="w-full"
                name="agreement"
                onChange={(e) => {
                  setSelectedAgreement(e.value);
                }}
                value={selectedAgreement === null ? "" : undefined}
                options={agreements?.map((agreement) => {
                  return {
                    label: agreement.title,
                    value: agreement._id,
                  };
                })}
              />
            </div>
          </>
        }
        {
          selectedReferral === "SP" && <>
            <div className="row m-2">
              <label>{t("Select Profile")}</label>
              <CustomSelect
                className="w-full"
                name="profile"
                onChange={(e) => {
                  setSelectedProfile(e.value);
                }}
                value={selectedProfile &&  {
                  label: `${selectedProfile.profileType} | ${selectedProfile.masterTradingAccountId?.login} | ${selectedProfile.slaves?.length} Members`,
                  value: selectedProfile,
                }}
                options={profiles?.map((profile) => {
                  return {
                    label: `${profile.profileType} | ${profile.masterTradingAccountId?.login} | ${profile.slaves?.length} Members`,
                    value: profile,
                  };
                })}
              />
            </div>
          </>
        }
        <div className="row col-md-auto align-content-end m-2">
          <Button
            onClick={() => {
              setSelectedAgreement(null);
              setSelectedReferral(null);
              setSelectedProfile(null);
            }}
          >
            {t("Clear")}
          </Button>
        </div>
      </AvForm>
      {selectedReferral && (referrals?.length > 0 || selectedProfile !== null) && <Card className="p-4 m-2">
        {renderBody(selectedReferral)}
      </Card>}
    </Container>
  );
};

export default withTranslation()(Referral);
