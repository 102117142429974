import React, { useEffect } from "react";
import {
  connect,
  useDispatch,
  useSelector
} from "react-redux";
import { withTranslation } from "react-i18next";
import AsyncAvFieldSelect from "./AsyncAvFieldSelect";
import { getSalesAgentsStart } from "store/users/actions";


const SearchableAgentDropdown = (props) => {
  const dispatch = useDispatch();

  const {
    placeholder = "Search",
    isRequired = false,
    name = "agent",
    defaultOptions = [],
    value = null,
    agents = [],
    clientData = null,
    onChange = () => { },
    isMulti = false,
    title
  } = props;

  const [agentOptions, setAgentOptions] = React.useState(defaultOptions);


  useEffect(() => {
    setAgentOptions(
      agents.filter((agent) => agent.roleId.title === title).map((agent) => ({
        value: agent._id,
        label: clientData && 
               (clientData.juniorBD?._id === agent._id ||
                clientData.seniorBD?._id === agent._id ||
                clientData.accountManager?._id === agent._id) 
          ? `${agent.firstName} ${agent.lastName} ${agent.roleId.title} - (Currently Assigned)` 
          : `${agent.firstName} ${agent.lastName} - ${agent.roleId.title}`
      }))
    );
  }, [agents, clientData]);


  useEffect(() => {
    if (!agents || agents.length === 0) {
      dispatch(getSalesAgentsStart({
        limit: 100,
        page: 1
      }));
    }
  }, []);

  return (
    <AsyncAvFieldSelect 
      name={name}
      options={agentOptions}
      label={props.t(`${title}`)}
      errorMessage={props.t(`${title} is required`)}
      defaultOptions={agentOptions || defaultOptions}
      value={value ? value : 
        (clientData?.accountManager?._id && title === "Account Manager" ? 
          {
            label : `${clientData.accountManager?.firstName} ${clientData.accountManager?.lastName}`,
            value : `${clientData.accountManager?._id}`
          } : 
          (clientData?.seniorBD?._id && title === "Senior BD" ? 
            {
              label : `${clientData.seniorBD?.firstName} ${clientData.seniorBD?.lastName}`,
              value : `${clientData.seniorBD?._id}`
            } : 
            (clientData?.juniorBD?._id && title === "Junior BD" ? 
              {
                label : `${clientData.juniorBD?.firstName} ${clientData.juniorBD?.lastName}`,
                value : `${clientData.juniorBD?._id}`
              } : "")))}
      isRequired={isRequired}
      placeholder={placeholder}
      isSearchable={true}
      backspaceRemovesValue={true}
      onChange={onChange}
      isMulti={isMulti}
    />
  );
};


const mapStateToProps = (state) => ({
  agents: state.usersReducer.salesAgent,
  agentsLoading: state.usersReducer.loading,
});
export default connect(mapStateToProps, null)(withTranslation()(SearchableAgentDropdown));