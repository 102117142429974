import { cpUrl } from "content";

export default [
  {
    type: "Register Live",
    url: `${cpUrl}/register/client/live`
  },
  {
    type: "Register Demo",
    url: `${cpUrl}/register/client/demo`
  },
  {
    type: "Register Sub IB",
    url: `${cpUrl}/register/client/ib`
  }
];
